import AuthForm from '../components/Auth/AuthForm';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const AuthPage = () => {
  return (
    <section className="bg--gray">
      <Container>
        <Row>
          <Col xs={12} sm={{ span: 6, offset: 3 }}>
            <div className="text-center mb-4">
                <h1 className="h3 mb-3 font-weight-normal">Delta Dental Reports Login</h1>
                <p>If you have any questions, please contact Meyocks.</p>
            </div>
            <AuthForm />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AuthPage;
