import { Fragment, useContext } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../../store/auth-context';

import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';

import classes from './MainNavigation.module.css';

const MainNavigation = () => {
  const authCtx = useContext(AuthContext);

  const isLoggedIn = authCtx.isLoggedIn;

  const logoutHandler = (event) => {
    event.preventDefault();
    authCtx.logout();
  };

  return (
    <Navbar className={classes.NavBar} expand="lg" fixed="top">
      <Container>
        <Navbar.Brand as={Link} to="/reports">
          <img className={classes.NavBar__img} src="/assets/img/dd-logo-2.png" alt="Delta Dental" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            {!isLoggedIn && (
              <Nav.Link as={Link} to="/auth" className={classes.navLink}>Login</Nav.Link>
            )}
            {isLoggedIn && (
              <Fragment>
                <Nav.Link as={Link} to="/reports" className={classes.navLink}>Reports</Nav.Link>
                <Nav.Link as={Link} to="/profile" className={classes.navLink}>Profile</Nav.Link>
              </Fragment>
            )}
            <Nav.Link as={Link} to="/contact" className={classes.navLink}>Contact</Nav.Link>
            {isLoggedIn && (
              <Nav.Link className={classes.navLink} onClick={logoutHandler}>Logout</Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default MainNavigation;
