import UserProfile from '../components/Profile/UserProfile'; 
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const ProfilePage = () => {
  return (
    <section className="bg--gray">
      <Container>
        <Row>
          <Col xs={12}>
            <UserProfile />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ProfilePage;
