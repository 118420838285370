import { useState } from "react";
import Alert from 'react-bootstrap/Alert';

const AlertMessage = (props) => {
  const [show, setShow] = useState(props.show);

  const handleClose = () => {
    setShow(false);
    props.clearMessage();
  };
  
  if (props.show && show) {
    return (
      <Alert variant={props.errorType} onClose={handleClose} dismissible>
        <Alert.Heading>{props.title}</Alert.Heading>
        <p className="mb-0">
          {props.message}
        </p>
      </Alert>
    );
  } else {
    return null;
  }
};

export default AlertMessage;
