import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Fragment } from 'react';
import ReportsGrid from '../Reports/ReportsGrid';

const ReportsProfile = () => {
  return (
      <Fragment>
        <Row>
          <Col xs={12} sm={{ span: 8, offset: 2 }}>
            <div className="text-center mb-4">
                <h1 className="h3 mb-3 font-weight-normal">Below are reports available for you to download.</h1>
                <p className="mb-5">If you have any questions, please contact Meyocks.</p>
            </div>
          </Col>
        </Row>
        <ReportsGrid />
      </Fragment>
  );
};

export default ReportsProfile;
