import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import classes from './Footer.module.css';

const Footer = (props) => {
  return (
    <footer className={classes.Footer}>
      <Container>
        <Row>
          <Col xs={12}>
            <p className={classes.Footer__copy}><small>Copyright &copy; Delta Dental. All Rights Reserved.</small></p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
