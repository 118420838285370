import { useContext, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import AuthContext from '../../store/auth-context';

const ReportCard = (props) => {
  const authCtx = useContext(AuthContext);
  const [idReportLoading, setIdReportLoading] = useState('');

  const handleReportClick = async (id, report_name) => {
    props.clearMessage();
    setIdReportLoading(id);
    const response = await fetch(`${process.env.REACT_APP_API_URL}reportservice/generatereportcsv`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authCtx.token}`
      },
      body: JSON.stringify({report_id: id})
    });
    if (!response.ok) {
      setIdReportLoading('');
      throw new Error('Something went wrong!');
    }
    const responseData = await response.json();
    setIdReportLoading('');
    const url = responseData.results;
    if (url === 'no') {
      props.alert({
        message: `There is no report available for ${report_name}.`,
        type: 'warning',
        show: true,
        title: 'No Report!'
      });
    } else if(url === 'failed') {
      props.alert({
        message: `${report_name} report was not able to generate. If this keeps on happening please contact support.`,
        type: 'danger',
        show: true,
        title: 'Failed Report!'
      })
    } else {
      window.open(url, '_blank');
    }
  };

  if (props.reports.length > 0) {
    const html = props.reports.map((report, index) => {
      return (
        <Col xs={12} md={4} key={index}>
          <Card className="mb-3">
            <Card.Body>
              <Card.Title>{report.name}</Card.Title>
              <Card.Text>
                {report.description}
              </Card.Text>
              {(idReportLoading !== report.id) &&
                <Button variant="primary" onClick={() => handleReportClick(report.id, report.name)}>Download report</Button>
              }
              {(idReportLoading === report.id) &&
                <p className="mb-0">Generating report...</p>
              }
            </Card.Body>
          </Card>
        </Col>
      );
    });
    return html;
  } else {
    return (
      <Col xs={12}>
        <p>There are no reports available for you to download.</p>
      </Col>
    );
  }
};

export default ReportCard;
