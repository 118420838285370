import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import { AuthContextProvider } from './store/auth-context';
import './fonts/woff2/Gotham-Light_Web.woff2';
import './fonts/woff/Gotham-Light_Web.woff';
import './fonts/woff2/Gotham-LightItalic_Web.woff2';
import './fonts/woff/Gotham-LightItalic_Web.woff';
import './fonts/woff2/Gotham-Book_Web.woff2';
import './fonts/woff/Gotham-Book_Web.woff';
import './fonts/woff2/Gotham-BookItalic_Web.woff2';
import './fonts/woff/Gotham-BookItalic_Web.woff';
import './fonts/woff2/Gotham-Medium_Web.woff2';
import './fonts/woff/Gotham-Medium_Web.woff';
import './fonts/woff2/Gotham-MediumItalic_Web.woff2';
import './fonts/woff/Gotham-MediumItalic_Web.woff';
import './fonts/woff2/Gotham-Bold_Web.woff2';
import './fonts/woff/Gotham-Bold_Web.woff';
import './fonts/woff2/Gotham-BoldItalic_Web.woff2';
import './fonts/woff/Gotham-BoldItalic_Web.woff';

ReactDOM.render(
  <AuthContextProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </AuthContextProvider>,
  document.getElementById('root')
);
