import { useRef, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ReCAPTCHA from 'react-google-recaptcha';
import classes from './Contact.module.css';
import useInput from '../../hooks/use-input';
import axios from 'axios';
import AlertMessage from '../AlertMessage/AlertMessage';
import AlertMessageObj from '../AlertMessage/AlertMessageObj';

const Contact = () => {
  const recaptchaRef = useRef();
  const [recaptchaValid, setRecaptchaValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasAlertMessage, setHasAlertMessage] = useState(AlertMessageObj);

  const onCaptchaChange = () => {
    const recaptchaValue = recaptchaRef.current.getValue();
    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}authenticateservice/validategooglecaptcha`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        captcha: recaptchaValue
      }
    }).then(function(res) {
      setRecaptchaValid(res.data.results.success);
    })
    .catch(function(error) {
      console.log(error);
    });
  };

  const {
    value: enteredName,
    isValid: enteredNameIsValid,
    hasError: nameInputHasError,
    valueInputChangeHanlder: nameChangedHanlder,
    inputBlurHandler: nameBlueHandler,
    reset: resetNameInput
  } = useInput(value => value.trim() !== '');

  const {
    value: enteredEmail,
    isValid: enteredEmailIsValid,
    hasError: emailInputHasError,
    valueInputChangeHanlder: emailChangedHanlder,
    inputBlurHandler: emailBlueHandler,
    reset: resetEmailInput
  } = useInput(value => value.includes('@'));

  const {
    value: enteredMessage,
    isValid: enteredMessageIsValid,
    hasError: messageHasError,
    valueInputChangeHanlder: messageChangedHanlder,
    inputBlurHandler: messageBlueHandler,
    reset: resetMessage
  } = useInput(value => value.trim() !== '');

  let formIsValid = false;

  if(enteredNameIsValid && enteredEmailIsValid && recaptchaValid && enteredMessageIsValid) {
    formIsValid = true;
  }
  
  const submitHandler = async (event) => {
    event.preventDefault();
    if (!formIsValid) {
      return;
    }
    setIsLoading(true);
    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}contactservice/sendcontact`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        name: enteredName,
        email: enteredEmail,
        message: enteredMessage
      }
    }).then(function(res) {
      setIsLoading(false);
      if (res.data.results.status) {
        resetNameInput();
        resetEmailInput();
        resetMessage();
        handleSetAlertMessage({
          message: res.data.results.message,
          type: 'success',
          show: true,
          title: 'Message sent!'
        });
      } else {
        //error
        handleSetAlertMessage({
          message: res.data.results.message,
          type: 'danger',
          show: true,
          title: 'Message not sent!'
        });
      }
    })
    .catch(function(error) {
      console.log(error);
      setIsLoading(false);
      handleSetAlertMessage({
        message: error.message,
        type: 'danger',
        show: true,
        title: 'Sending Error!'
      });
    });
  };

  const handleClearMessage = () => {
    setHasAlertMessage(AlertMessageObj);
  };

  const handleSetAlertMessage = (msgObj) => {
    setHasAlertMessage({
      message: msgObj.message,
      type: msgObj.type,
      show: msgObj.show,
      title: msgObj.title
    });
  };

  return (
    <section className="bg--gray">
      <Container>
        <Row>
          <Col xs={12} sm={{ span: 6, offset: 3 }}>
            <div className="text-center mb-4">
                <h1 className="h3 mb-3 font-weight-normal">Contact us</h1>
                <p className="mb-5">Do you have any questions or need help with something? Please fill out the form below.</p>
            </div>
          </Col>
        </Row>
        {hasAlertMessage.show && 
          <Row>
            <Col>
              <AlertMessage 
                errorType={hasAlertMessage.type} 
                message={hasAlertMessage.message} 
                show={hasAlertMessage.show} 
                title={hasAlertMessage.title} 
                clearMessage={handleClearMessage}
              />
            </Col>
          </Row>
        }
        <Row>
          <Col xs={12} sm={{ span: 6, offset: 3 }}>
            
            <form onSubmit={submitHandler}>
              <div className={classes.control}>
                <label htmlFor='name'>Name</label>
                <input 
                  type='text' 
                  id='name' 
                  placeholder="Name"
                  required 
                  onChange={nameChangedHanlder} 
                  onBlur={nameBlueHandler}
                  value={enteredName} 
                />
                {nameInputHasError && <p className={classes.errorText}>Name must not be empty</p>}
              </div>
              <div className={classes.control}>
                <label htmlFor='email'>Email address</label>
                <input 
                  type='email' 
                  id='email' 
                  placeholder="Email address"
                  required 
                  onChange={emailChangedHanlder} 
                  onBlur={emailBlueHandler}
                  value={enteredEmail} 
                />
                {emailInputHasError && <p className={classes.errorText}>Enter a valid email.</p>}
              </div>
              <div className={classes.control}>
                <label htmlFor='message'>Message</label>
                <textarea 
                  type='text' 
                  id='message' 
                  placeholder="Message" 
                  rows="4"
                  required 
                  onChange={messageChangedHanlder} 
                  onBlur={messageBlueHandler}
                  value={enteredMessage} 
                />
                {messageHasError && <p className={classes.errorText}>Enter a valid email.</p>}
              </div>
              
              <div>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey="6LceVgATAAAAAOFnvO1bc-7FI48BLUkasbpvVH0T" 
                  className="mb-4" 
                  onChange={onCaptchaChange}
                />
              </div>
              <div className={classes.actions}>
                {isLoading && <p>Sending request...</p>}
                {!isLoading && <button type="submit">Submit</button>}
              </div>
            </form>

          </Col>
        </Row>
      </Container>
  </section>
  )
};

export default Contact;
