import { useEffect, useState, useContext, Fragment } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import AuthContext from '../../store/auth-context';
import ReportCard from './ReportCard';
import AlertMessage from '../AlertMessage/AlertMessage';
import AlertMessageObj from '../AlertMessage/AlertMessageObj';
import Alert from 'react-bootstrap/Alert';

const ReportsGrid = () => {
  const [reports, setReports] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [httpError, setHttpError] = useState();
  const authCtx = useContext(AuthContext);
  const { token } = authCtx;
  const [hasAlertMessage, setHasAlertMessage] = useState(AlertMessageObj);

  useEffect(() => {

    const fetchReports = async () => {
      const response = await fetch(`${process.env.REACT_APP_API_URL}reportservice/getuserreports`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({user_id: 1})
      });
      if (!response.ok) {
        throw new Error('Something went wrong!');
      }

      const responseData = await response.json();
      if (!responseData.success) {
        handleSetAlertMessage({
          message: responseData.msg,
          type: 'danger',
          show: true,
          title: 'Error!'
        });
      }
      let loadedReports = responseData.results;
      setReports(loadedReports);
      setIsLoading(false);
    };

    fetchReports().catch((error) => {
      console.log('in error');
      setIsLoading(false);
      setHttpError(error.message);
      handleSetAlertMessage({
        message: error.message,
        type: 'danger',
        show: true,
        title: 'Network Error!'
      });
    });

  }, [token]);

  const handleClearMessage = () => {
    setHasAlertMessage(AlertMessageObj);
  };

  const handleSetAlertMessage = (msgObj) => {
    setHasAlertMessage({
      message: msgObj.message,
      type: msgObj.type,
      show: msgObj.show,
      title: msgObj.title
    });
  };

  return (
    <Fragment>
      {hasAlertMessage.show && 
        <Row>
          <Col>
            <AlertMessage 
              errorType={hasAlertMessage.type} 
              message={hasAlertMessage.message} 
              show={hasAlertMessage.show} 
              title={hasAlertMessage.title} 
              clearMessage={handleClearMessage}
            />
          </Col>
        </Row>
      }
      {isLoading &&
        <Row>
          <Col className="text-center">
            <Spinner animation="border" variant="primary" />
          </Col>
        </Row>
      }
      {(reports && reports.length > 0 && !isLoading && !httpError) &&
        <Row>
          <ReportCard 
            reports={reports} 
            alert={handleSetAlertMessage} 
            clearMessage={handleClearMessage} 
            />
        </Row>
      }
      {(reports && reports.length === 0 && !isLoading && !httpError) &&
        <Row>
          <Col>
            <Alert variant={'warning'}>
              <Alert.Heading>Your Reports!</Alert.Heading>
              <p className="mb-0">
                There are no reports available for you to download.
              </p>
            </Alert>
          </Col>
        </Row>
      }
    </Fragment>
  )
};

export default ReportsGrid;
