import { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import ProfileForm from './ProfileForm';
import AlertMessage from '../AlertMessage/AlertMessage';
import AlertMessageObj from '../AlertMessage/AlertMessageObj';

const UserProfile = () => {
  const [hasAlertMessage, setHasAlertMessage] = useState(AlertMessageObj);

  const handleClearMessage = () => {
    setHasAlertMessage(AlertMessageObj);
  };

  const handleSetAlertMessage = (msgObj) => {
    setHasAlertMessage({
      message: msgObj.message,
      type: msgObj.type,
      show: msgObj.show,
      title: msgObj.title
    });
  };
  return (
      <Fragment>
        <Row>
          <Col xs={12} sm={{ span: 6, offset: 3 }}>
            <div className="text-center mb-4">
                <h1 className="h3 mb-3 font-weight-normal">User Profile</h1>
                <p className="mb-5">Your user settings are listed below. If you have any questions please use <Link className="js-nav-link" to="/contact">contact us</Link>.</p>
            </div>
          </Col>
        </Row>
        {hasAlertMessage.show && 
          <Row>
            <Col xs={12} md={{ span: 8, offset: 2}}>
              <AlertMessage 
                errorType={hasAlertMessage.type} 
                message={hasAlertMessage.message} 
                show={hasAlertMessage.show} 
                title={hasAlertMessage.title} 
                clearMessage={handleClearMessage}
              />
            </Col>
          </Row>
        }
        <Row>
          <Col xs={12} md={{ span: 8, offset: 2}}>
            <Card>
              <Card.Body>
                <Card.Title>Change Password</Card.Title>
                <ProfileForm clearMessage={handleClearMessage} setMessage={handleSetAlertMessage} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Fragment>
  );
};

export default UserProfile;
