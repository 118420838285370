import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ReportsProfile from '../components/Reports/ReportsProfile';

const ReportsPage = () => {
  return (
    <section className="bg--gray">
      <Container>
        <Row>
          <Col xs={12}>
            <ReportsProfile />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ReportsPage;
