import { useState, useRef, useContext, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import AuthContext from '../../store/auth-context';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import useInput from '../../hooks/use-input';
import AlertMessageObj from '../AlertMessage/AlertMessageObj';
import AlertMessage from '../AlertMessage/AlertMessage';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import classes from './AuthForm.module.css';

const AuthForm = () => {
  const history = useHistory();
  const recaptchaRef = useRef();
  const [recaptchaValid, setRecaptchaValid] = useState(false);
  const authCtx = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [hasAlertMessage, setHasAlertMessage] = useState(AlertMessageObj);
  const {
    value: enteredPassword,
    isValid: enteredPasswordIsValid,
    hasError: passwordInputHasError,
    valueInputChangeHanlder: passwordChangedHanlder,
    inputBlurHandler: passwordBlurHandler,
    reset: resetPasswordInput
  } = useInput(value => value.trim() !== '');

  const {
    value: enteredEmail,
    isValid: enteredEmailIsValid,
    hasError: emailInputHasError,
    valueInputChangeHanlder: emailChangedHanlder,
    inputBlurHandler: emailBlurHandler,
    reset: resetEmailInput
  } = useInput(value => value.includes('@'));

  let formIsValid = false;

  if(enteredPasswordIsValid && enteredEmailIsValid && recaptchaValid) {
    formIsValid = true;
  }

  const onCaptchaChange = () => {
    const recaptchaValue = recaptchaRef.current.getValue();
    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}authenticateservice/validategooglecaptcha`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        captcha: recaptchaValue
      }
    }).then(function(res) {
      setRecaptchaValid(res.data.results.success);
    })
    .catch(function(error) {
      console.log(error);
    });
  };

  const submitHandler = (event) => {
    event.preventDefault();
    if (!formIsValid) {
      return;
    }
    setIsLoading(true);
    fetch(
      'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyCxrVdUXIvWhTNbW8TgVnyWcPJQQmZUNe4',
      {
        method: 'POST',
        body: JSON.stringify({
          email: enteredEmail.toLowerCase(),
          password: enteredPassword,
          returnSecureToken: true
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).then((res) => {
      setIsLoading(false);
      if (res.ok) {
        return res.json();
      } else {
        return res.json().then(data => {
          let errorMessage = 'Authentication failed!';
          if (data && data.error && data.error.message) {
            errorMessage = data.error.message;
          }
          throw new Error(errorMessage);
        });
      }
    }).then(data => {
      const expirationTime = new Date(new Date().getTime() + (+data.expiresIn * 1000));
      authCtx.login(data.idToken, expirationTime.toISOString());
      history.replace('/reports');
    }).catch(err => {
      if (err.message === 'INVALID_PASSWORD') {
        err.message = 'The password and/or username is not valid.';
      }
      resetPasswordInput();
      resetEmailInput();
      handleSetAlertMessage({
        message: err.message,
        type: 'danger',
        show: true,
        title: 'Login Error!' 
      })
    });
  };

  const handleClearMessage = () => {
    setHasAlertMessage(AlertMessageObj);
  };

  const handleSetAlertMessage = (msgObj) => {
    setHasAlertMessage({
      message: msgObj.message,
      type: msgObj.type,
      show: msgObj.show,
      title: msgObj.title
    });
  };

  return (
    <Fragment>
      {hasAlertMessage.show && 
        <Row>
          <Col>
            <AlertMessage 
              errorType={hasAlertMessage.type} 
              message={hasAlertMessage.message} 
              show={hasAlertMessage.show} 
              title={hasAlertMessage.title} 
              clearMessage={handleClearMessage}
            />
          </Col>
        </Row>
      }
      <div>
        <form onSubmit={submitHandler}>
          <div className={classes.control}>
            <label htmlFor='email'>Email address</label>
            <input 
              type='email' 
              id='email' 
              placeholder="Email address"
              required 
              onChange={emailChangedHanlder} 
              onBlur={emailBlurHandler}
              value={enteredEmail} 
            />
            {emailInputHasError && <p className={classes.errorText}>Enter a valid email.</p>}
          </div>
          <div className={classes.control}>
            <label htmlFor='password'>Password</label>
            <input 
              type='password' 
              id='password' 
              placeholder="Password"
              required 
              onChange={passwordChangedHanlder} 
              onBlur={passwordBlurHandler}
              value={enteredPassword} 
            />
            {passwordInputHasError && <p className={classes.errorText}>Enter a valid password.</p>}
          </div>
          <div>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6LceVgATAAAAAOFnvO1bc-7FI48BLUkasbpvVH0T" 
              className="mb-4" 
              onChange={onCaptchaChange}
            />
          </div>
          <div className={classes.actions}>
            {isLoading && <p>Sending request...</p>}
            {!isLoading && <button type="submit">Login</button>}
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export default AuthForm;
