import { useContext } from 'react';
import AuthContext from '../../store/auth-context';
import classes from './ProfileForm.module.css';
import useInput from '../../hooks/use-input';

const ProfileForm = (props) => {
        const authCtx = useContext(AuthContext);
        const {
            value: enteredPassword,
            isValid: enteredPasswordIsValid,
            hasError: passwordInputHasError,
            valueInputChangeHanlder: passwordChangedHanlder,
            inputBlurHandler: passwordBlurHandler,
            reset: resetPasswordInput
        } = useInput(value => value.trim().length >= 6);

        let formIsValid = false;

        if (enteredPasswordIsValid) {
            formIsValid = true;
        }

        const submitHandler = (event) => {
            event.preventDefault();
            props.clearMessage();
            if (!formIsValid) {
                return;
            }

            fetch(
                'https://identitytoolkit.googleapis.com/v1/accounts:update?key=AIzaSyCxrVdUXIvWhTNbW8TgVnyWcPJQQmZUNe4', {
                    method: 'POST',
                    body: JSON.stringify({
                        idToken: authCtx.token,
                        password: enteredPassword,
                        returnSecureToken: false
                    }),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            ).then(res => {
                resetPasswordInput();
                if (res.ok) {
                    return res.json();
                } else {
                    return res.json().then(data => {
                        let errorMessage = 'Password change failed!';
                        if (data && data.error && data.error.message) {
                            errorMessage = data.error.message;
                        }
                        throw new Error(errorMessage);
                    });
                }
            }).then(data => {
                authCtx.changeToken(data.idToken);
                props.setMessage({
                    message: 'Your password has been changed.',
                    type: 'success',
                    show: true,
                    title: 'Password Changed!'
                });
            }).catch(err => {
                if (err.message === 'TOKEN_EXPIRED') {
                    err.message = 'Please log out and then log in to reset your password.';
                }
                props.setMessage({
                    message: err.message,
                    type: 'danger',
                    show: true,
                    title: 'Password Reset Error!'
                });
            });
        };

        return ( <
            form onSubmit = { submitHandler }
            className = { classes.form } >
            <
            div className = { classes.control } >
            <
            label htmlFor = 'new-password' > New Password < /label> <
            input type = 'password'
            id = 'new-password'
            placeholder = "Password"
            required onChange = { passwordChangedHanlder }
            onBlur = { passwordBlurHandler }
            value = { enteredPassword }
            minLength = "7" /
            > {
                passwordInputHasError && < p className = { classes.errorText } > Enter a valid password at least 7 characters long. < /p>} <
                /div> <
                div className = { classes.action } >
                <
                button > Change Password < /button> <
                /div> <
                /form>
            );
        }

        export default ProfileForm;